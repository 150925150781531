import { createRouter, createWebHistory } from "vue-router";
import configure from "@/serve/configure";
import hotelConfigure from "@/serve/hotelConfigure";
import { mainStore } from "@/store/index";
import {getDaysBetween} from "@/common"
//import i18n from '@/i18n/index'

import index from "../views/index.vue";
import marketings from "../views/marketings.vue";
import error from "../views/error.vue";
import { cache_sync } from '@/utils/cache';

const routes = [
  {
    path: "/error",
    name: "error",
    component: error,
  },
  {
    path: "/:id/index",
    name: "index",
    component: index,
  },
  {
    path: "/:id/marketings/:type",
    name: "marketings",
    component: marketings,
  },
  {
    path: "/:id/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login.vue"),
  },
  {
    path: "/:id/setuser/:type",
    name: "setuser",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/userDetail.vue"),
  },
  {
    path: "/:id/hotelsearch",
    name: "hotelsearch",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/hotel/hotelsearch.vue"),
  },
  {
    path: "/:id/hoteldetail",
    name: "hoteldetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/hotel/hoteldetail.vue"),
  },
  {
    path: "/:id/hotelbooking",
    name: "hotelbooking",
    component: () => import("../views/hotel/hotelbooking.vue"),
  },
  {
    path: "/:id/searching/:keys",
    name: "searching",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/search.vue"),
  },
  {
    path: "/:id/searching_im/:type",
    name: "searching_im",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/searchingIm.vue"),
  },
  {
    path: "/:id/product_details/:code/:language",
    name: "product_details",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/product_details.vue"),
  },
  {
    path: "/:id/orderselectdate/:code/:gcode",
    name: "orderSelectDate",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/orderSelectDate.vue"),
  },
  {
    path: "/:id/orderform/:code/:gcode",
    name: "orderform",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/orderform.vue"),
  },
  {
    path: "/:id/orderSupPay/:ocode/:code",
    name: "ordersuppay",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pay/order_sup_pay.vue"),
  },
  {
    path: "/:id/orderpay/:ocode",
    name: "orderpay",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pay/orderpay.vue"),
  },
  {
    path: "/:id/successful/:merchant_trade_number/:token",
    name: "successful",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pay/successful.vue"),
  },
  {
    path: "/:id/my",
    name: "my",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/my/my.vue"),

  },
  {
    path: "/:id/useredit",
    name: "useredit",
    component: () =>
      import("../views/my/useredit.vue"),
  },
  {
    path: "/:id/orderdetails/:ocode",
    name: "orderdetails",
    component: () =>
      import("../views/my/orderDetail.vue"),
  },
  {
    path: "/:id/myorder",
    name: "myorder",
    component: () =>
      import("../views/my/myorder.vue"),
  },
  {
    path: "/:id/creditlimit",
    name: "creditlimit",
    component: () =>
      import("../views/my/creditlimit.vue"),
  },
  {
    path: "/:id/user_deal",
    name: "userDeal",
    component: () =>
      import("../views/my/userDeal.vue"),
  },
  {
    path: "/:id/deal_details/:ocode",
    name: "dealDetails",
    component: () =>
      import("../views/my/dealDetails.vue"),
  },
  {
    path: "/:id/shopinfo",
    name: "shopInfo",
    component: () =>
      import("../views/my/shopInfo.vue"),
  },
  {
    path: "/:id/settlement",
    name: "settleMent",
    component: () =>
      import("../views/my/settleMent.vue"),
  },
  {
    path: "/:id/commontravelers",
    name: "commonTravelers",
    component: () =>
      import("../views/my/commonTravelers.vue"),
  },
  {
    path: "/:id/pointsinfo",
    name: "pointsInfo",
    component: () =>
      import("../views/my/pointsInfo.vue"),
  },
  {
    path: "/:id/uservip",
    name: "userVip",
    component: () =>
      import("../views/my/userVip.vue"),
  },
  {
    path: "/:id/commission",
    name: "commission",
    component: () =>
      import("../views/my/commission.vue"),
  },
  {
    path: "/:id/couponlist",
    name: "couponList",
    component: () =>
      import("../views/my/couponList.vue"),
  },
  {
    path: "/:id/appointment_date/:ocode",
    name: "appointment_date",
    component: () =>
      import("../views/pre_sale/preSaleDate.vue"),
  },
  {
    path: "/:id/appointment_form/:ocode",
    name: "appointment_form",
    component: () =>
      import("../views/pre_sale/preSaleForm.vue"),
  },
  {
    path: "/:id/loosepulley",
    name: "loosePulley",
    component: () =>
      import("../views/loosePulley.vue"),
  },
  {
    path: "/:id/test",
    name: "test",
    component: () =>
      import("../views/test.vue"),
  },
  {
    path: "/:id/member",
    name: "member",
    component: () =>
      import("../views/my/member.vue"),
  },
  {
    path: "/:id/agent/account_auth/:token",
    name: "account_auth",
    component: () => import("../views/agent/account_auth.vue"),
  },
  {
    path: "/:id/price",
    name: "priceOrder",
    component: () =>
      import("../views/askPrice/priceOrder.vue"),
  },
  {
    path: "/:id/query_detail/:pcode",
    name: "queryDetail",
    component: () =>
      import("../views/askPrice/queryDetail.vue"),
  },
  {
    path: "/:id/select_product",
    name: "selectProduct",
    component: () =>
      import("../views/askPrice/selectProduct.vue"),
  },
  {
    path: "/:id/select_data/:code/:gcode",
    name: "selectData",
    component: () =>
      import("../views/askPrice/selectData.vue"),
  },
  {
    path: "/:id/unsubscribe/:acid/:actoken/:language",
    name: "unsubscribe",
    component: () =>
      import("../views/unsubscribe.vue"),
  },
  {
    path: "/:id/recharge",
    name: "recharge",
    component: () =>
      import("../views/my/recharge.vue"),
  },
  {
    path: "/:id/record",
    name: "record",
    component: () =>
      import("../views/my/record.vue"),
  },
  {
    path: "/:id/south-australia",
    name: "southAustralia",
    component: () =>
      import("../views/marketing/southAustralia.vue"),
  },
  {
    path: "/:id/south-australia-info/:key",
    name: "southAustraliaInfo",
    component: () =>
      import("../views/marketing/southAustraliaInfo.vue"),
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // console.log('不跳了', savedPosition);
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});
//前置守卫
router.beforeEach((to, from, next) => {
  
  // console.log('路由前置守卫', to);

  const store = mainStore();

  //记录上一个页面，便于登录后跳回
  const notfrom = ["successful", "orderpay", "setuser", "orderform", "account_auth"];
  if ((to && to.name && to.name.toLowerCase() == "login") && (from && from.name && notfrom.indexOf(from.name.toLowerCase()) === -1)) {
    store.setLoginRoute(from);
  }

  //验证需要登录的页面
  let usertoken = store.getUserToken
  // "orderform","myorder","orderdetails", 
  const loginto = ["useredit", "my", "hotelbooking", "pointsinfo", "uservip",
    "commission", "commontravelers", "settlement", "creditlimit", "userdeal", "dealdetails", "shopinfo"]
  if (!usertoken && to && to.name && loginto.indexOf(to.name.toLowerCase()) > -1) {
    next({ name: 'login', params: { id: to.params.id } });
  }

  if (to.name !== 'error' && !to.params.id) {
    if (process.env.NODE_ENV !== 'production') {
      next({ name: 'index', params: { id: 456817 } });
    }
    else {
      if (to.name === undefined) {
        next({ name: 'index', params: { id: to.path.replace(/\//g, "") } });
      }
      else next({ name: 'error' })
    }
  }
  else next()
});

//前置预处理
router.beforeResolve(async (to, from, next) => {
  if (to && to.name && to.name.toLowerCase() != "error") {
    try {
      //判断访问终端
      var browser = {
        versions: function () {
          var u = navigator.userAgent,
            app = navigator.appVersion;
          return {
            trident: u.indexOf('Trident') > -1, //IE内核
            presto: u.indexOf('Presto') > -1, //opera内核
            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,//火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
            iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, //是否iPad
            webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
            weixin: u.indexOf('MicroMessenger') > -1, //是否微信 （2015-01-22新增）
            qq: u.match(/\sQQ/i) == " qq" //是否QQ
          };
        }(),
        language: (navigator.browserLanguage || navigator.language).toLowerCase()
      }
      // console.log(browser.versions);

      //判断是否手机端访问
      var userAgentInfo = navigator.userAgent.toLowerCase();
      var Agents = ["android", "iphone",
        "symbianos", "windows phone",
        "ipad", "ipod"];
      //var ly=document.referrer;  //返回导航到当前网页的超链接所在网页的URL
      // console.log(userAgentInfo);
      if (!to.params.id) {

        next({ name: 'error' });
        //console.log(urlParam);
      }
      else {
        for (var v = 0; v < Agents.length; v++) {
          if (userAgentInfo.indexOf(Agents[v]) >= 0) {
            window.location.href = window.location.href.replace(".sctt.net", ".m.sctt.net");
            // console.log("wap端判断进来了")
          }
        }
      }

      const store = mainStore();
      let version = "3.6.46";  //标记版本号，便于更新版本重新获取common信息
      let cookie_version = store.version;
      // console.log(store.commonData,store.HeaderData,'commonData,xxxxxxxxxxxxxxx');
      // console.log(version,cookie_version,'版本号1');
      if (store.commonData && store.commonData.configure && store.commonData.configure.bid > 0) {
        if (to.params.id != store.commonData.configure.bid) {
          // console.log("bid不同__更改版本号")
          store.logout();
          store.setLoginRoute({});
          store.clearCommonData();
          store.changeVersion(version);
        }
      }
      // console.log(version,cookie_version,'版本号2');
      if (version !== cookie_version) {
        // console.log("版本不同__更改版本号",version,cookie_version)
        store.logout();
        store.setLoginRoute({});
        store.clearCommonData();
        store.changeVersion(version);
      }
      // console.log(store.commonData,store.HeaderData,'commonData,xxxxxxxxxxxxxxx2');
      await configure.GetCommon(to.params.id).then((res) => {
        // console.log("GetCommon:", to.params.id, res);
        if (res.code == 0) {
          // console.log("进入GetCommon：", res);
          store.upcommonData(res.data);
          if (sessionStorage.getItem('commonLanguage')) {
            store.upHeaderLanguage(sessionStorage.getItem('commonLanguage'))
            sessionStorage.removeItem('commonLanguage')
          }
        }
        if (res.msg == "ok") {
          // console.log(localStorage.getItem("SCTT_router_num"),store.getUserToken,store.getqimoClient,store.qimoClientIdBySctt ,'store.commonData');
          // if( localStorage.getItem("SCTT_router_num") == 1 && store.getUserToken && store.qimoClientIdBySctt && store.commonData.configure.chatid) {
          //   let _7moor_language = "ZHCN";
          //   if(store.HeaderData.language == 1){
          //       _7moor_language = "EN";
          //   }
          //   else if(store.HeaderData.language == 2){
          //       _7moor_language = "ZHCN";
          //   }
          //   else if(store.HeaderData.language ==3){
          //       _7moor_language = "ZHCN";
          //   }
          //   else if(store.HeaderData.language ==4){
          //       _7moor_language = "EN";
          //   }
          //   window.qimoClientId = store.getqimoClient
          //   let _7moor_script = document.createElement('script');
          //    _7moor_script.async = 'async';
          //   _7moor_script.src = `https://ykf-webchat.7moor.com/javascripts/7moorInit.js?accessId=${store.commonData.configure.chatid}&autoShow=false&language=` + _7moor_language;
          //   document.body.appendChild(_7moor_script);
          //   localStorage.setItem("SCTT_router_num",2)
          // }
          document.getElementsByTagName('body')[0].style.setProperty('---themeColor1', '#' + store.commonData.configure.webstyle.firstColour);
          document.getElementsByTagName('body')[0].style.setProperty('---themeColor2', '#' + store.commonData.configure.webstyle.secondColour);
          document.getElementsByTagName('body')[0].style.setProperty('---themeColor3', '#' + store.commonData.configure.webstyle.thirdColour);
          document.getElementsByTagName('body')[0].style.setProperty('---themeColor4', '#' + store.commonData.configure.webstyle.fourthColour);
          document.getElementsByTagName('body')[0].style.setProperty('---themeColor5', '#' + store.commonData.configure.webstyle.fifthColour);
          document.getElementsByTagName('body')[0].style.setProperty('---themeColor6', '#' + store.commonData.configure.webstyle.sixthColour);
          document.getElementsByTagName('body')[0].style.setProperty('---themeColor7', '#' + store.commonData.configure.webstyle.sevenColor);
          document.title = store.commonData.configure.title;
          //设置网站图标
          if (store.commonData && store.commonData.configure && store.commonData.configure.logo) {
            if (store.commonData.configure.logo.indexOf(".jpeg") > -1) {
              document.getElementById("favicon").type = "image/gif";
            } else if (store.commonData.configure.logo.indexOf(".png") > -1) {
              document.getElementById("favicon").type = "image/png";
            }
            document.getElementById("favicon").href = store.commonData.configure.logo;
          }
          let usertoken = store.getUserToken
          if (to.name.toLowerCase() !== "login" && usertoken) {
            if (localStorage.getItem('login_now_date')) {
              let new_date = new Date().valueOf()
              let old_data = localStorage.getItem('login_now_date')
              // console.log(new_date,old_data, new_date - old_data,'ceshixxxxxxxxxxxxxxxxxxxx');
              if( (new_date - old_data) > 604800000) {
                store.logout();
                localStorage.removeItem('login_now_date')
                next({ name: 'login',params:{ id: to.params.id } })
              }
            }
          }
          if (to.name.toLowerCase() !== "login") {
            if(sessionStorage.getItem("type_id")) {
              sessionStorage.removeItem("type_id")
            }
            let old_time_date = store.scttpc_login_time_agent
            let old_time = getDaysBetween(old_time_date,new Date())
            if (store.commonData.configure.type == 2 && to.name.toLowerCase() != "account_auth" && to.name.toLowerCase() != "unsubscribe") {
              if (!usertoken || old_time >= 1) {
                store.logout();
                next({ name: 'login', params: { id: to.params.id } });
              }
            }
            // console.log(store.usertype,old_time,to,'store.usertype');
            if(store.usertype == 1 ) {
              if(old_time >= 1) {
                store.logout();
                next({ name: 'login', params: { id: to.params.id } });
              }
            }
          }

          // if(to.name=="login" || to.name=="index"|| to.name=="searching" ) {
          //   next()
          // } else {
          //   const store = mainStore();
          //   let usertoken = store.getUserToken
          //   if(usertoken) {
          //     next()
          //   }else {
          //     next({ name: 'login',params:{ id: to.params.id } });
          //   }
          // }
          //console.log("store.locale：",store.locale);
          //i18n.locale = store.locale;
              next()
        }
        else {
          //失败
          next({ name: 'error' });
        }
      });





    }
    catch (error) {
      // console.log("处理错误，然后取消导航", error);
      next({ name: 'error' });
      //return false
      // if (error instanceof NotAllowedError) {
      //   // ... 处理错误，然后取消导航
      //   console.log("处理错误，然后取消导航",error);
      //   return false
      // } else {
      //   console.log("意料之外的错误，取消导航并把错误传给全局处理器",error);
      //   // 意料之外的错误，取消导航并把错误传给全局处理器
      //   throw error
      // }
    }
  }
  else {
    next();
  }
});

export default router;
